import React from "react"
import styled from "styled-components"
import Img from 'gatsby-image'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

import { Section, SectionTitle, SectionDescription, BreadCrumb, Badge} from "../components/section"

import WidthIcon from '../images/width.svg'
import HeightIcon from '../images/height.svg'
import LenthIcon from '../images/lenth.svg'
import BuildingIcon from '../components/Icons/BuildingIcon'
import PhoneIcon from '../components/Icons/PhoneIcon'
import QouteIcon from '../components/Icons/QouteIcon'
import DefaultButton from "../components/button/DefaultButton"
import DefaultOutlineButton from "../components/button/DefaultOutlineButton"
import ColorBoard from "../components/ColorBoard"
import RequestForm from "../components/RequestForm"
import BreakpointUp from "../components/Media/BreakpointUp"
import RelatedProductCarousel from '../components/RelatedProductCarousel'
import QuotePopup from '../components/QuotePopup'

import generateHTML from '../utils/generateHTML';
import Content, { HTMLContent } from '../components/Content';

const ProductGrid = styled.div`
	position:relative;
	&:before{
		content:"";
		background-color:#F6F6F6;
		position:absolute;
		bottom:0;
		left:0;
		right:0;
		border-top:1px solid #ddd;
		height:85%;
		${BreakpointUp.sm`		
			height:66.666667%;
		`}
	}	
`

const Card = styled.div`
	display:flex;
	align-items:center;
	flex-direction:column;
	max-width: 770px;
	width:100%;
	margin:0 auto;
	${BreakpointUp.sm`		
		padding: 0 40px;
	`}
`
const CardTitle = styled.h1`
  font-family: 'Roboto', serif;
	margin:0 0 10px;
	color:#020304;
	font-size:22px;
	line-height:34px;
	font-weight:700;

`
const CardFigure = styled.div`	
  	width:100%;
	position:relative;
	& .gatsby-image-wrapper{
		height:100%;
	}
`
const ProductId = styled.div`
	display: block;
	margin-bottom: 10px;	
	background-color: #020304;
	color: #fff;
	padding: 4px 10px;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;  
	text-transform: uppercase;
	width:fit-content;
`
const CardContent = styled.div`
	width:100%;
	padding:60px 0 30px 0;	
	${BreakpointUp.sm`
	  padding:40px 0 50px 0;		
	`}
`
const HelpBlock = styled.p`
	font-size: 12px;
	color: #999;
	margin: 5px 0 30px;
	line-height:22px;
	${BreakpointUp.sm`
		font-size: 14px;
	`}
`
const PriceBlock = styled.div`
	display: flex;
    align-items: center;    
	& small{
		display:block;
		font-size:14px;
		color:#999;
	}
`
const TotalPrice = styled.div`
	& strong{
		font-family: 'IBM Plex Serif', serif;      
		font-weight:700;
		font-size:34px;
		color:#020304;
		line-height:50px;
	}

`
const DepositPrice = styled.div`
	margin-left:40px;
	& strong{
		font-family: 'IBM Plex Serif', serif;      
		font-weight:700;
		font-size:22px;
		color:#666;
	}
`

const ProductSize = styled.div`
	background-color:#fff;
	border:1px solid #ddd;
	position:absolute;
	display:block;
	transform:translateX(-50%);		
	right:auto;
	bottom:-35px;
	left:50%;
	width:100%;
	max-width: 300px;
	${BreakpointUp.sm`
		max-width:80px;
		transform:translateY(-50%);		
		bottom:auto;
		left:auto;
		top:50%;
		right:0;
		
	`}
	${BreakpointUp.sm`
		right:-40px;
	`}

`

const ProductItemSize = styled.div`
	background-color:#fff;	
	width:98px;
	height:60px;
	padding:8px 15px;
	border-left:1px solid #ddd;
	border-top:none;
	display:inline-block;
	text-align: center;
	&:first-child{
		border-left:none;
	}
	${BreakpointUp.sm`	
		display:block;
		width:78px;
		height:70px;
		padding:12px 15px;
		border-top:1px solid #ddd;
		border-left:none;
		&:first-child{
			border-top:none;
		}
	`}
	.icon{
		height:28px;
		display: flex;
		align-items: flex-end;
		margin-bottom: 2px;	
		justify-content: center;
	}
	.text{
		display:block;
		font-size:12px;
		color:#666;
		font-weight:500;
		white-space: nowrap;
		line-height:20px;
		b{
			font-weight:700;
			color:#020304;
		}
	}
	
`

const ProductInfo = styled.div`
	position:relative;
	display:flex;	
	flex-wrap:wrap;
	margin:0 -15px;
	
`
const ProductText = styled.div`
	position: relative;
	width: 100%;
	padding:0 15px;
	${BreakpointUp.sm`
		flex: 0 0 58.333333%;
		max-width: 58.333333%;			
	`}
`
const ButtonToolbar = styled.div`
	position: relative;
	width: 100%;
	padding:0 15px;
	${BreakpointUp.sm`	
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	`}
	& a{
		display:block;
		.btn{
			display:block;
			width:100%;
			padding:16px 24px;		
		}
		+ a{
			margin-top:15px;
		}
	}
	
`
const ProductService = styled.div`
	background-color:#fff;
	display: grid;
    justify-content: space-between;
	grid-column-gap: 10px;
	box-shadow: 0px 10px 30px rgba(0,0,0,0.2);	
	max-width: 690px;
    width: 100%;
    margin: 0 auto;
    padding: 0 45px;
	grid-template-columns: repeat(1, auto);    
	${BreakpointUp.sm`
		grid-template-columns: repeat(3, auto);    
		position: absolute;
		transform: translateX(-50%);
		left: 50%;
		bottom: -35px;
	`}
`

const ProductServiceItem = styled.div`	
	display:flex;
	align-items:center;	
	padding:20px;
	border-top: 1px solid #ddd;		
	&:first-child{
		border-top:none;	
	}
	${BreakpointUp.sm`	
		border-top: none;
	`}
	img{
		margin-right: 15px;
		fill: #666;
		width: 50px;
	}

`
const ProductServiceText = styled.span`	
	font-weight:500;
	color:#020304;
	line-height:20px;
`

const ProductBottomGrid = styled.div`	
	display: flex;
	flex-wrap: wrap;
	margin:0 -15px;
	${BreakpointUp.lg`
		margin:0 -30px;
	`}
`
const ProductBottomItem = styled.div`	
	position:relative;
	padding:0 15px;
	margin-bottom:30px;
	width:100%;
	${BreakpointUp.lg`
		padding:0 30px;
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
		border-left:1px solid #ddd;

	`}
	&:first-child{
		border-left:none;
	}
	h2{
		color:#020304;
		font-family: 'Roboto', serif;
		font-size:22px;
		margin-bottom:15px;
		${BreakpointUp.sm`
			margin-bottom:30px;
		`}
	}
`

const StickyFooter = styled.div`
	display: none;
	@media(max-width: 767px) {
		display: block;
		position: fixed;
		background: white;
		z-index: 10;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 70px;
		text-align: center;
		padding: 10px;
		border-top: 1px solid #eee;
		box-shadow: 0 10px 40px rgba(0, 0, 0, .2);
		& .quote {
			display: inline-block;
			margin-left: 10px;
			&:hover {
				cursor: pointer;
			}
		}
	}
`

class Product extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isVisibleQuote: false,
		}
		this.showQuote = this.showQuote.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this)
	}
	showQuote() {
    this.setState({
      isVisibleQuote: true,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.add('popup-open')
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.remove('popup-open')
    }
  }
  render() {
  	const { data, location } = this.props
  	const { isVisibleQuote } = this.state
  	const productData = data.contentfulProduct
		const productList = data.allContentfulProduct
		const PostContent = HTMLContent || Content;
		const productID =  productData.id.slice(-12);
		const commonMetaTitle = productData.productName + " - Shop "+productData.productName+" Online"
		const commonMetaDescription = "Shop for "+productData.productName+" online at the best prices. Our "+productData.productName+" is manufactured with the highest quality steel and built to last."
		return(
			<Layout location={location}>
				<SEO 
					title={productData.metaTitle?productData.metaTitle:commonMetaTitle}
					description={commonMetaDescription}
				/>
				<Section pt="140px" pb="90px" mpb="60px">
					<ProductGrid>
						<div className="container">
							<BreadCrumb mb="10px"><Link to='/'>Home</Link>/<Link to={productData.productCategory.link}>{productData.productCategory.name}</Link>/<span>{productData.productName}</span></BreadCrumb>
							<Card className="card">
								<CardFigure className="card-img">
									<Badge bottom="30px" top="auto" color="#2F9800" bgColor="#d5eacc">{productData.popularLabel}</Badge>	
									<Img fluid={productData.image.fluid} />
									<ProductSize>
										<ProductItemSize>
											<span className="icon"><img src={WidthIcon} alt='width' /></span>
											<span className="text">Width <b>{productData.width}</b></span>
										</ProductItemSize>
										<ProductItemSize>
											<span className="icon"><img src={LenthIcon} alt="length" /></span>
											<span className="text">Lenth <b>{productData.length}</b></span>
										</ProductItemSize>
										<ProductItemSize>
											<span className="icon"><img src={HeightIcon} alt="height" /></span>
											<span className="text">Height <b>{productData.height}</b></span>
										</ProductItemSize>
									</ProductSize>
								</CardFigure>
								<CardContent className="card-body">
									<ProductInfo>
										<ProductText>
											<ProductId>SKU - {productID}</ProductId>
											<CardTitle>{productData.productName}</CardTitle>              
											<PriceBlock>
												<TotalPrice><strong>${productData.totalPrice}</strong><small>Starting Price</small></TotalPrice>
												{/* {productData.depositPrice?(
													<DepositPrice><strong>${productData.depositPrice}</strong><small>Down Payment</small></DepositPrice>	
												):(
													<></>
												)} */}
											</PriceBlock>
											<HelpBlock>{productData.priceDescription}</HelpBlock>
										</ProductText>
										<ButtonToolbar>
											<a href='tel:8776005980'><DefaultButton icon={<PhoneIcon />} aria-label="Call Us Now" text="(877) 793-6179" /></a>
											<Link to='/3d-steel-building-cost-estimator'><DefaultOutlineButton icon={<BuildingIcon />} text="Design your Building" /></Link>
										</ButtonToolbar>
									</ProductInfo>	
								</CardContent>          
							</Card>
							<ProductService>
								{ productData.featuresImage && (
									productData.featuresImage.map((item, i) => {
										return(
											<ProductServiceItem key={i}>
												<img src={item.file.url} alt={item.title} />
												<ProductServiceText>{item.title}</ProductServiceText>
											</ProductServiceItem>
										)
									})
								)}
							</ProductService>
						</div>
					</ProductGrid>
				</Section>
				<Section pb="40px" bdrBottom="#ddd">		
					<div className="container">
						<ProductBottomGrid>
							<ProductBottomItem>
								<h2>Description</h2>		
								<PostContent
		              content={generateHTML(productData.productDescription.childMarkdownRemark.html)}
			          />
							</ProductBottomItem>
							<ProductBottomItem>
								<h2>Available Colors</h2>		
								<ColorBoard />
							</ProductBottomItem>
							<ProductBottomItem>
								<h2>Request A Free Quote</h2>		
								<RequestForm location={location} />
							</ProductBottomItem>
						</ProductBottomGrid>	
					</div>	
				</Section>
				<Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="#F6F6F6">
					<div className="container">
					{productData.secondSectionTitle && <SectionTitle maxWidth="550px">{productData.secondSectionTitle}</SectionTitle>}
					{productData.secondSectionDescription && <SectionDescription>{productData.secondSectionDescription.secondSectionDescription}</SectionDescription> }	
					<RelatedProductCarousel data={productList} showQuote={this.showQuote}/>
					</div>
	      </Section>   
				<QuotePopup
				  isVisible={isVisibleQuote}
					onClose={this.onClosePopup}
					location={location}
				/>
				<StickyFooter className="product-footer">
					<a href='tel:8776005980'><DefaultOutlineButton icon={<PhoneIcon />} aria-label="Call Us Now" text="Call Us Now" /></a>
					<div className="quote" onClick={this.showQuote}><DefaultButton icon={<QouteIcon />} text="Get a Quote" /></div>
				</StickyFooter>
			</Layout>
		)
  }
}

export default Product

export const pageQuery = graphql`
  query productQuery($id: String!) {
    contentfulProduct(id: { eq: $id }) {
    	metaTitle
    	id
    	productName
    	popularLabel
      image {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      width
      height
      length
      totalPrice
      depositPrice
      priceDescription
      featuresImage {
        title
        file {
          url
        }
      }
      productRoof {
        roofName
      }
      popularLabel
      url
      productCategory {
        name
        link
      }
      productDescription {
        childMarkdownRemark {
          html
        }
      }
      secondSectionTitle
      secondSectionDescription {
        secondSectionDescription
      }
    }
    allContentfulProduct(
	  	sort: {fields: totalPrice}
	  ) {
	    edges {
	      node {
					id
	      	productName
		      image {
		        fluid {
		          base64
		          aspectRatio
		          src
		          srcSet
		          srcSetWebp
		          srcWebp
		        }
		      }
		      width
		      height
		      length
		      totalPrice
		      depositPrice
		      popularLabel
		      priceDescription
		      featuresImage {
		        title
		        file {
		          url
		        }
		      }
		      productRoof {
		        roofName
		      }
		      popularLabel
		      url
		      productCategory {
		        name
						link
		      }

	      }
	    }
	  }
  }
`
